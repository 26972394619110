var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "seoJobsDetail" },
    [
      _c(
        "div",
        { staticClass: "seoJobsDetail__contentElements" },
        [
          _c("content-element-children", {
            attrs: { children: _vm.seoJob.content.children },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "green-container",
        { attrs: { "add-container-styles": false } },
        [
          _c("job-item-list", { attrs: { jobs: _vm.relatedApiJobs } }),
          _vm._v(" "),
          _c("JobButtons", {
            attrs: { context: _vm.context.pageContext },
            on: { listClick: _vm.onListClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }