var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jobButtons" },
    [
      _c(
        "btn",
        {
          attrs: {
            id: "jobButtons__" + _vm.identifier[0],
            href: _vm.context.linkRepository.get("jobList"),
          },
          nativeOn: {
            click: function ($event) {
              return _vm.onListClick.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("navigation.teaser.btn.jobMarket")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "btn",
        {
          attrs: {
            id: "jobButtons__" + _vm.identifier[1],
            href: _vm.context.linkRepository.get("applyUnsolicited"),
            isGhost: "",
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("navigation.teaser.btn.application")) +
              "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }