
































import {isEmpty} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import JobButtons from '../../../../Component/Job/JobButtons/JobButtons.vue';
import JobItemList from '../../../../Component/Job/JobItemList/JobItemList.vue';
import GreenContainer from '../../../../Component/Layout/GreenContainer/GreenContainer.vue';
import OpenGraphMixin from '../../../../Mixin/OpenGraphMixin';
import SeoJobsMixin from '../../../../Mixin/SeoJobsMixin';
import {JsonLdService} from '../../../../Service/JsonLdService';

export default {
    name: 'SeoJobsDetail',
    mixins: [SeoJobsMixin, OpenGraphMixin],
    components: {JobButtons, GreenContainer, JobItemList},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        seoJob(): PlainObject
        {
            return this.context.initialState.response.data;
        },
        title(): string
        {
            return isEmpty(this.seoJob.headline) ? this.seoJob.name : this.seoJob.headline;
        },
        ogDescription(): string
        {
            return this.getOgDescription(isEmpty(this.seoJob.description) ? this.title : this.seoJob.description);
        }
    },
    metaInfo()
    {
        return {
            title: isEmpty(this.seoJob.metaTitle) ? this.title : this.seoJob.metaTitle,
            meta: this.metaTags.concat([
                {
                    'name': 'description',
                    'content': this.ogDescription,
                    'vmid': 't3-description'
                }
            ]),
            script: [
                JsonLdService.makeBreadcrumb(this.seoJob.name)
            ]
        };
    },
    created()
    {
        const job = this.seoJob;
        this.context.store.set(AppStoreKeys.PAGE_HEADLINE_OVERRIDE, this.title);
        if (job.teaserImages) {
            this.context.store.set(AppStoreKeys.PAGE_TEASER_IMAGE_OVERRIDE, job.teaserImages);
        }
        if (job.useVideoHeader) {
            this.context.store.set(AppStoreKeys.PAGE_TEASER_VIDEO_OVERRIDE, job.mediaVideo);
            this.context.store.set(AppStoreKeys.PAGE_TEASER_VIDEO_POSTER_OVERRIDE, job.mediaVideoPoster);
        }
        this.context.pageContext.pageMeta.setCanonical(job.link);
    }
};
