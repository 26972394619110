/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.26 at 18:48
 */

import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {map} from '@labor-digital/helferlein/lib/Lists/map';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {Collection} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import {Job} from '../Interface/Job';
import {JobDemand} from '../Interface/JobDemand';
import {BranchAndGeoLocationService} from '../Service/BranchAndGeoLocationService';
import {JobService} from '../Service/JobService';

export default {
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            apiJobResult: [],
            location: BranchAndGeoLocationService.getCurrentLocation()
        };
    },
    mounted()
    {
        this.loadRelatedApiJobs();
    },
    computed: {
        demand(): JobDemand
        {
            return this.context.initialState.get('jobDemand', {});
        },
        relatedApiJobs(): Array<void> | PlainObject<Job>
        {
            return this.apiJobResult;
        }
    },
    methods: {
        loadRelatedApiJobs()
        {
            const demand = this.demand;
            this.context.resourceApi
                .getCollection('job', {
                    filter: {
                        location: this.location,
                        radius: 1000,
                        occupation: JobService.concatValueList(demand.occupation),
                        contractType: JobService.concatValueList(demand.contractType),
                        employmentType: JobService.concatValueList(demand.employmentType),
                        requiredWords: JobService.concatValueList(demand.requiredWords),
                        forbiddenWords: JobService.concatValueList(demand.forbiddenWords)
                    },
                    sort: ['-latest'],
                    page: {
                        size: 6
                    }
                })
                .then((response: Collection) => {
                    this.apiJobResult = map(response.getAll(), (v) => v.getAll());
                });
        },
        onListClick()
        {
            JobService.initializeSerpsPage(this.demand, this.context.initialState.name);
        }
    }
};
