
































import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import Btn from '../../Misc/Btn/Btn.vue';

export default {
    name: 'JobButtons',
    components: {Btn},
    props: {
        context: {
            type: Object,
            required: true
        } as PageContext,
        /**
         * Give the buttons a unique id.
         * Example: ['jobList', 'applyUnsolicited']
         * Will result in giving the first btn "jobButtons__jobList" and the second btn "jobButtons__applyUnsolicited"
         * This is also the default
         */
        identifier: {
            type: Array,
            default()
            {
                return ['jobList', 'applyUnsolicited'];
            }
        }
    },
    methods: {
        onListClick()
        {
            this.$emit('listClick');
        }
    }
};
